import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValueLoadable } from 'recoil';
import ItemsCarousel from 'react-items-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToLeft,
  faArrowToRight,
} from '@fortawesome/pro-duotone-svg-icons';
import { bannerAdsListSelector } from '../../../../../atoms/Selectors';
import Loader from '../../../../shared-components/loader/Loader';

function Banner() {
  const banners = useRecoilValueLoadable(bannerAdsListSelector);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const { t, i18n } = useTranslation();

  // console.log(banners?.contents?.data?.data);

  return (
    <>
      {banners?.state === 'loading' && (
        <div className=" flex flex-1 flex-col items-center justify-center h-20">
          <Loader />
        </div>
      )}
      {banners?.state === 'hasValue' && !banners?.contents?.data?.data?.tops?.length > 0 && (
        <div className="flex flex-shrink-0 text-white bg-header bg-center bg-cover h-28 md:h-72">
          <div className="flex flex-col flex-1 justify-end p-4" />
        </div>
      )}
      {banners?.state === 'hasValue' && banners?.contents?.data?.data?.tops?.length > 0 && (
        <div className="bg-headerDark bg-center bg-cover">
          <ItemsCarousel
            infiniteLoop
            gutter={1}
            activePosition="right"
            chevronWidth={50}
            disableSwipe={false}
            alwaysShowChevrons
            numberOfCards={1}
            slidesToScroll={1}
            showSlither
            firstAndLastGutter
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full"
              >
                <FontAwesomeIcon icon={faArrowToRight} />
              </button>
            }
            leftChevron={
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 bg-white shadow rounded-full"
              >
                <FontAwesomeIcon icon={faArrowToLeft} />
              </button>
            }
          >
            {_.map(banners?.contents?.data?.data?.tops,
              (s) => (
                <div key={s.id} className="mx-auto flex">
                  {!s.actionValue && (
                    <img
                      alt={s.name}
                      src={s.imageUrl}
                      className="rounded-xl m-5 h-52 mx-auto shadow-lg"
                    />
                  )}
                  {s.actionType === 1 && (
                    <button
                      type="button"
                      className="mx-auto"
                      onClick={() => {
                        if (s.actionType === 1) {
                          window.open(s.actionValue, '_blank').focus();
                        }
                      }}
                    >
                      <img
                        alt={s.name}
                        src={s.imageUrl}
                        className="rounded-xl m-5 h-52 mx-auto shadow-lg"
                      />
                    </button>
                  )}
                </div>
              )
            )}
          </ItemsCarousel>
        </div>
      )}
    </>
  );
}

export default Banner;
